.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #16181c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.message{
  padding-top: 60px;
  padding-bottom: 60px;
}

.column{
  padding-right: 5px;
  padding-left: 5px;
}

.button-panel{
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.finish-button{
  padding-top: 20px;
}

.score{
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-content{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;

}

.scoreboard {
  position: absolute;
  bottom: 75px;
}

.round {
  position: absolute;
  top: 75px;
}

.result {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result-header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.result-bit {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: calc(10px + 0.5vmin);
}
.game-code-panel{
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: calc(10px + 0.5vmin);
  position: absolute;
  bottom: 25px;
}

#clipboard-icon {
  padding-left: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.directions {
  margin: 15px;
  font-size: 15px;
  width: 400px;
  text-align: left;
}

.home-buttons {
  padding-top: 20px;
  display: flex;
  flex-direction: horizontal;
}

.flex-button {
  margin: 15px;
}

.akq-image {
  border-radius: 10px;
  width: 400px;
  margin: 30px;
}

.nav-menu {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
}

#home-nav {
  position: absolute;
  left: 20px;
  top: 20px;
}

#rule-nav {
  position: absolute;
  right: 20px;
  top: 20px;
}

@media (max-width : 500px) {
  h1 {
    font-size: 100px
  }

  .akq-image {
    border-radius: 10px;
    width: 300px;
    margin: 30px;
  }

  .directions {
    margin: 15px;
    font-size: 15px;
    width: 320px;
    text-align: left;
  }
}